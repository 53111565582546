import Head from 'next/head'
import dynamic from 'next/dynamic'
import { DataStore } from '@aws-amplify/datastore'

import { CommunitySlide, HomePage } from '../src/models'
import services from '../services'
import { useEffect, useState } from 'react'

import HeroSection from '../components/frontPage/HeroSection'
import HowItWork from '../components/frontPage/Howitwork'
const FeaturedIn = dynamic(() => import('../components/frontPage/FeaturedIn'))
const Services = dynamic(() => import('../components/frontPage/Services'))
const Journal = dynamic(() => import('../components/frontPage/Journal'))
const Support = dynamic(() => import('../components/frontPage/Support'))
const Community = dynamic(() => import('../components/frontPage/Community'))
const PublicPage = dynamic(() => import('../components/PublicPage'))

const Home = ({data}) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setLoaded(true)
    }, [])
    return <PublicPage>
        <Head>
            <title>Little Honey Money</title>
            <meta property="og:title" content="Little Honey Money"/>
            <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_URL}`}/>
            <meta property="og:image" content="/images/hero.jpg"/>
            <meta property="og:type" content="website"/>
            <meta property="og:description" content={data.main_hero.title}/>
            <meta property="og:locale" content="en_US"/>
        </Head>
        <div className="wrapper">
            <HeroSection data={data}/>
            <Support data={data}/>
            {loaded && <>
                <HowItWork data={data}/>
                <FeaturedIn/>
                <Services data={data}/>
                <Journal data={data}/>
                <Community data={data}/>
            </>}
        </div>
    </PublicPage>
}

export const getStaticProps = async () => {
    const models = await DataStore.query(HomePage)
    const community_slides = (await DataStore.query(CommunitySlide)).filter(item => item.homepageID === models[0].id);
    const posts = await services.api.journal.getAllPosts()
    const categories = await services.api.journal.getAllCategories()
    return {
        props: {
            data: {
                ...models[0],
                community_slides: community_slides.map(item => ({...item})),
                posts: posts.slice(0,2).map(item => {
                    const [category_id] = item.categories
                    const category = categories.find(({ id }) => id === category_id)
                    return {
                        id: item.id,
                        title: item.title.rendered,
                        link: `/journal/${item.slug}`,
                        category: category?.name,
                        thumb: item._embedded['wp:featuredmedia'][0].source_url
                    }
                })
            }
        },
        revalidate: 60 * 60 * 24
    }
}

export default Home
