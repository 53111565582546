import Imgix from '../../UI/Imgix'
import Link from 'next/link'

export const HowItWorkHead = ({data}) =>(
  <div className='how-it-work__head'>
    <h2 className='h2'>{data.how_it_works_title}</h2>
    <Link prefetch={false} href={data.how_it_works_cta_link}>
      <a className='btn-link'>{data.how_it_works_cta_text}</a>
    </Link>
 </div>
)

export const HowItWorksCarousel = ({item, index}) => (
  <div className='how-it-work__item how-it-work__item--active'>
     <figure className='how-it-work__figure'>
         <Imgix width={320} height={386} src={item.image} placeholder={null} alt=""/>
         <figcaption className='how-it-work__caption'>
            <span className='how-it-work__number'>{index + 1}</span>
            <p className='how-it-work__text'>{item.title}</p>
         </figcaption>
     </figure>
  </div>
)

export const HowItWorkRepeater = ({ item, index }) => (
  <div className='how-it-work__item how-it-work__item--active'>
      <figure className='how-it-work__figure'>
        <Imgix width={402} height={419} src={item.image} loading="lazy" placeholder={null} alt=""/>
        <figcaption className='how-it-work__caption'>
            <span className='how-it-work__number'>{index + 1}</span>
            <p className='how-it-work__text'>{item.title}</p>
        </figcaption>
      </figure>
  </div>
)
