import { useRouter } from 'next/router'
import NextLink from 'next/link'

const Link = ({ children, href, className, activeClass = 'active__state', ...props }) => {
    const router = useRouter()
    const { pathname } = router

    return <NextLink href={href}>
        <a className={`${className || ''} ${pathname === href ? activeClass : ''}`} href={href} {...props}>
            {children}
        </a>
    </NextLink>
}

export default Link
