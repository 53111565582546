import { Swiper, SwiperSlide } from 'swiper/react'
import Observer from '../Observer'
const Slider = ({swiperProps, data = [], ComponentForRender}) => (
  <Observer>
    <Swiper {...swiperProps} >
      {data.map((item, index) =>
        <SwiperSlide key={index}>
          <ComponentForRender item={item} index={index}/>
        </SwiperSlide>
      )}
    </Swiper>
  </Observer>
)

export default Slider;
