import { useEffect, useRef, useState } from "react"
const Observer = (props) => {
  const [visible, setVisible] = useState(false);
  const myRef = useRef(null);
  useEffect(() => {
    const visibleSection = myRef?.current
        const threshold = 0.1
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.intersectionRatio > threshold) {
                setVisible(true)
            }
        }, {
            root: null,
            rootMargin: '200px',
            threshold
        })
        observer.observe(visibleSection)
  }, [myRef])
  
  return (
    <div ref={myRef}>
      {visible ? props.children : null}
    </div>
  )
}

export default Observer