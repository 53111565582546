import Imgix from '../UI/Imgix'
import Link from '../UI/Link'

const HeroSection = ({ data }) => {
    return <section className="d-flex align-items-content hero">
        <div className="hero__bg show-desk">
            <Imgix width={1440} height={900} src={data.main_hero.image} placeholder={null} loading="lazy" alt=""/>
        </div>
        <div className="hero__bg show-mob">
            <Imgix width={414} height={407} src={data.main_hero.image_mobile} placeholder={null} loading="lazy" alt=""/>
        </div>
        <div className="hero__content-wrap">
            <div className="container">
                <div className="hero__content">
                    <h6 className="hero__subtitle">{data.main_hero.title}</h6>
                    <h1 className="hero__title">{data.main_hero.description}</h1>
                    <Link className="btn-fill hero__link" href={data.main_hero.cta_link}>
                        {data.main_hero.cta_text}
                    </Link>
                </div>
            </div>
        </div>
    </section>
}

export default HeroSection
