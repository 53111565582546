import { useEffect, useState } from 'react';
import { HowItWorksCarousel, HowItWorkHead, HowItWorkRepeater } from './Partials/HowItWorkRepeater';
import Slider from '../UI/Slider';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

SwiperCore.use([Navigation, Autoplay]);
const HowItWork = ({data}) => {
   const [carousel, setCarousel] = useState(false);

   const isMobile = () => {
      window.innerWidth <= 1023.98 ? setCarousel(true) : setCarousel(false);
   };

   useEffect(() => {
      window.addEventListener('resize', isMobile);
      isMobile();
      return () => window.removeEventListener('resize', isMobile);
   }, [carousel]);

   return (
      <section className='how-it-work'>
         <div className='container'>
            <HowItWorkHead data={data} />
            {carousel ? (
               <Slider
                  data={data.how_it_works_step}
                  ComponentForRender={HowItWorksCarousel}
                  swiperProps={{
                    slidesPerView: 1,
                    speed: 600,
                    autoplay: {
                       delay: 3000,
                       disableOnInteraction: false
                    }
                  }}
                  // loop={true}
                  // loopedSlides={10}
               />
            ) : (
               <div className='how-it-work__grid'>
                  {data.how_it_works_step.map((item, index) =>
                     <HowItWorkRepeater item={item} index={index} key={index} />
                  )}
               </div>
            )}
         </div>
      </section>
   );
};

export default HowItWork;
