import Image from "next/image";
import { imgixLoader } from "@delicious-simplicity/next-image-imgix-loader";

const Imgix = ({ ...props }) => {
    const imgSrc = imgixLoader(props,
        {
            w: props.width,
            h: props.height,
            fm: 'webp',
            fit: 'crop',
            auto: 'compress'
        })?.replace("%25", "%")

    return <Image
      loader={() => imgSrc}
      placeholder={props.width < 40 && props.height < 40 ? null : 'blur'}
      blurDataURL={imgSrc}
      src={imgSrc}
      alt={props.alt}
      width={props.width}
      height={props.height}
      {...props}
    />
}

export default Imgix
